/*jshint strict: true, undef: true*/
/*global load_email_list,wysiwyg,socketio,get_template_name
*/


function activateEmailReplyEditor ($modal, htmlContent){
  "use strict";
  $("#reply-email-textarea").wysiwyg({
    css: '/++theme++dkg.theme/consulting/css/editor.css',
    initialContent: '',
    autoGrow: true,
    controls: {
      h1: { visible: false },
      h2: { visible: false },
      h3: { visible: false },
      code: { visible: false },
      removeFormat: { visible: false },
      createLink: { visible: false },
      unLink: { visible: false },
      insertHorizontalRule: { visible: false },
      insertImage: { visible: false },
      insertTable: { visible: false },
      subscript: { visible: false },
      superscript: { visible: false },
      redo: { visible: false },
      undo: { visible: false },
      justifyCenter: { visible: false },
      justifyFull: { visible: false },
      justifyLeft: { visible: false },
      justifyRight: { visible: false },
      indent: { visible: false },
      outdent: { visible: false },
      strikeThrough: { visible: false },
      bold: { tooltip: "fett" },
      italic: { tooltip: "kursiv" },
      insertUnorderedList: { tooltip: "unsortierte Liste einfügen" },
      insertOrderedList: { tooltip: "sortierte Liste einfügen" }
    }
  });
}


function bindReplyEmailAction (){
  "use strict";
  var selectorEmailActionTrigger = '.reply-email-action';
  $(document).on('click', selectorEmailActionTrigger, function(e) {
    e.preventDefault();
    var $modal = $('#consultingModal'),
      emailReplyViewUrl,
      emailContentHtml,
      dbContentHtml,
      $dbContent;

    emailReplyViewUrl = $(selectorEmailActionTrigger)
      .attr('rel') + '?ajax_load=1';

    $modal.find(".modal-dialog").attr("id", "reply-email");
    $modal.find(".modal-dialog").addClass("modal-lg");
    $modal.find(".modal-title").text("Auf E-Mail antworten");
    $modal.find(".modal-footer").empty();
    $modal.find(".modal-footer").append('<button type="button" data-loading-text="sende E-Mail..." class="btn btn-default btn-primary">Senden</button>');
    $modal.find(".modal-footer").append('<button type="button" class="btn btn-default" data-dismiss="modal">Abbrechen</button>');
    $modal.find(".modal-body").html('<div class="spinner-wrapper"><li class="fa fa-spinner fa-spin"></li></div>');
    $modal.modal('show');

    $.ajax({
      url: emailReplyViewUrl,
      dataType: 'html',
      success: function(data) {
        $dbContent = $($.parseHTML(data)).find("#db-content");
        dbContentHtml = $dbContent.parent().html();
        emailContentHtml = $dbContent.find("#reply-email-textarea").parent().html();
        $modal.find(".modal-body").html(dbContentHtml);
      }
    })
      .done(function () {
        activateEmailReplyEditor();
      })
    ;
  });
}


function bindReplyEmailSendAction (){
  "use strict";
  $(document).on('click', '#consultingModal #reply-email button.btn-primary',
      function(e) {
        var sendReplyEmailUrl,
          data,
          btn = $(this);
        e.preventDefault();
        btn.button('loading');
        sendReplyEmailUrl = $(".reply-email-action")
          .attr('rel')
          .split('/')
          .slice(0,-1)
          .join("/")
        ;
        sendReplyEmailUrl = sendReplyEmailUrl + '/send-reply?ajax_load=1';
        data = $("#db-content form").serializeArray();
        $.post(sendReplyEmailUrl, data)
          .done(function (data){
            btn.button('reset');
            if (data) {
              $.bootstrapGrowl(
                "Nachricht konnte nicht gesendet werden!\n<br />" + data,
                {
                  type: 'error',
                  align: 'center',
                  delay: 8000,
                  offset: {
                    from: 'top', amount: 300
                  }
                }
              );
              return;
            }
            $.bootstrapGrowl(
              "Nachricht wurde gesendet.",
              {type: 'info', align: 'center'}
            );
            $("#consultingModal").modal('hide');
            load_email_list();
          })
        ;

      }
  );
}


function bindInboxOutboxTabs() {
  "use strict";
  var emailListAsJsonBaseUrl = $("#email-list").attr('rel');
  $(".inbox-headline").on("click", function(e) {
    e.preventDefault();
    $(".inbox-headline").addClass("active");
    $(".outbox-headline").removeClass("active");
    $("#email-list").attr('rel', emailListAsJsonBaseUrl);
    load_email_list();
  });
  $(".outbox-headline").on("click", function(e) {
    e.preventDefault();
    $(".inbox-headline").removeClass("active");
    $(".outbox-headline").addClass("active");
    $("#email-list").attr('rel', emailListAsJsonBaseUrl + '?outgoing=1');
    load_email_list();
  });
}


function closeTicket() {
  "use strict";
  if (window.confirm("Wollen Sie das Ticket wirklich schliessen?")) {
    $("input[name=consulting_form_close]").val("true");
    $("#anfrageform").submit();
  }
}


function deleteTicket() {
  "use strict";
  if (window.confirm("Wollen Sie das Ticket wirklich löschen?")) {
    $("input[name=consulting_form_delete]").val("true");
    $("#anfrageform").submit();
  }
}


function formOnScroll() {
  "use strict";
  var active_ids, page = $(document);
  var scrollTop = page.scrollTop();
  
  if (scrollTop < 200) {
    active_ids = ["li.contact", "div.block.contact"];
  } else if (scrollTop < 260) {
    active_ids = ["li.questioner", "div.block.questioner"];
  } else if (scrollTop < 315) {
    active_ids = ["li.time", "div.block.time"];
  } else if (scrollTop < 620) {
    active_ids = ["li.cancertype", "div.block.cancertype"];
  } else if (scrollTop < 840) {
    active_ids = ["li.questions", "div.block.questions"];
  } else if (scrollTop < 1090) {
    active_ids = ["li.campaign", "div.block.campaign"];
  } else {
    active_ids = ["li.statistic", "div.block.statistic"];
  }
  // Set inactive
  $("#form-navigation ul.nav > li").removeClass("active");
  $("#form-blocks div.block").removeClass("active");
  // Set active
  active_ids.forEach(function(entry) {
    $(entry).addClass("active");
  });
}


function enableFormScroll() {
  "use strict";
  var new_top,
      block,
      position,
      diff_base,
      diff_fak,
      diff;
  $("#form-navigation li").click(function(e) {
    $("#form-navigation li").removeClass("active");
    $("div.block").removeClass("active");
    new_top = 0;
    var trigger = $(this);
    var block_selector = trigger.data('block');
    block = $("div.block." + block_selector);
    position = block.position();
    diff_base = 65;
    diff_fak = 63;
    diff = diff_base + trigger.index() * diff_fak;
    new_top = position.top - diff + 54;
    if (new_top < 0) {
      new_top = 0;
    }
    $(document).scrollTop(new_top);
    trigger.addClass("active");
    block.addClass("active");
  });
}


// Questions Add and Edited
function setQuestionsEdited(search_url) {
  "use strict";
  var parent,
      link;
  $("table.question input").keyup(function(e) {
    parent = $(this).parent();
    link = parent.find("a");
    search_url = link.attr("href").split("=")[0] + "=";
    search_url += $(this).attr("value");
    link.attr("href", search_url);
  });
}


// CONSULTING FORM SUBMIT PRE STEP
function formSubmitPreStep(search_url) {
  "use strict";
  var saveticket,
      savestatistic;
  $("#submitanfrage").click(function(e) {
    e.preventDefault();
    $("#shadow").show();
    $("#submit_consulting_form-wrapper").show();
    /* close again */
    $("#shadow").click(function() {
      $("#submit_consulting_form-wrapper").hide();
      $("#shadow").hide();
    });
    $("#close_submit_consulting_form").click(function() {
      $("#submit_consulting_form-wrapper").hide();
      $("#shadow").hide();
    });
    /* save or not */
    $("#submit_consulting_form").click(function() {
      saveticket = $("#saveticket").attr("checked");
      savestatistic = $("#savestatistic").attr("checked");
      if (saveticket) {
        $("#consulting_form_create").val("true");
      }
      $("#anfrageform").submit();
    });
  });
}


function noticeToggle() {
  "use strict";
  $("#form-notice").unbind("click").click(function (e) {
    if (!$(e.target).hasClass("notoggle")) {
      if ($("#form-notice").hasClass("active")) {
        $("#form-notice").removeClass("active");
      } else {
        $("#form-notice").addClass("active");
      }
    }
  });
}


function bindQuestionerAppendix() {
  "use strict";
  var checked = $("input[name=questioner]:checked"),
      gparent,
      questioner,
      additional,
      additonalContainer,
      label,
      name;
  $("td.questioner-additional-container").hide();
  if (checked.size()) {
    gparent = checked.parent().parent();
    additional = gparent.find("td.additional");
    if (additional.hasClass("active")) {
      label = gparent.find("td.label");
      name = label.html();
      $("#label-questionerage").html("Alter " + name);
      $("#label-questionerlanguage").html("Sprache " + name);

      questioner = additional.parent().find("input[name=questioner]").attr("value");
      additonalContainer = $("#questioner-additional-" + questioner);
      $("#questionerappendix").appendTo(additonalContainer);
      additonalContainer.show();

      $("#questionerappendix").addClass("active");
    } else {
      $("#questionerappendix").removeClass("active");
    }
  }
  $("input[name=questioner]").click(function () {
    $("td.questioner-additional-container").hide();
    gparent = $(this).parent().parent();
    additional = gparent.find("td.additional");
    if (additional.hasClass("active")) {
      label = gparent.find("td.label");
      name = label.html();
      $("#label-questionerage").html("Alter " + name);
      $("#label-questionerlanguage").html("Sprache " + name);

      questioner = $(this).attr("value");
      additonalContainer = $("#questioner-additional-" + questioner);
      $("#questionerappendix").appendTo(additonalContainer);
      additonalContainer.show();

      $("#questionerappendix").addClass("active");
    } else {
      $("#questionerappendix").removeClass("active");
    }
  });
}


function bindShipping() {
  "use strict";
  var checkbox = $("input[name=shipping]");
  if (checkbox.attr("checked")) {
    $("#shipping").addClass("active");
  }
  checkbox.click(function () {
    if (this.checked === true) {
      $("#shipping").addClass("active");
    } else {
      $("#shipping").removeClass("active");
    }
  });
}


function bindState(prename) {
  "use strict";
  var countryselect = $("select[name=" + prename +"country]"),
      country,
      select;
  country = countryselect.val();
  select = $("select[name=" + prename + "state]");
  select.find("option.state").hide();
  select.removeClass();
  if (country === "deutschland") {
    select.find("option.state.german").show();
    select.find("option.default").html("Bundesland");
    select.addClass("german");
  } else if (country === "schweiz") {
    select.find("option.state.swiss").show();
    select.find("option.default").html("Kanton");
    select.addClass("swiss");
  } else if (country === "sterreich") {
    select.find("option.state.austrich").show();
    select.find("option.default").html("Bundesland");
    select.addClass("austrich");
  }
  countryselect.unbind("change").change(function() {
    country = $(this).val();
    select = $("select[name=" + prename + "state]");
    select.find("option.state").hide();
    select.removeClass();
    if (country === "deutschland") {
      select.find("option.state.german").show();
      select.find("option.default").html("Bundesland");
      select.find("option.default").attr("selected", "true");
      select.addClass("german");
    } else if (country === "schweiz") {
      select.find("option.state.swiss").show();
      select.find("option.default").html("Kanton");
      select.find("option.default").attr("selected", "true");
      select.addClass("swiss");
    } else if (country === "sterreich") {
      select.find("option.state.austrich").show();
      select.find("option.default").html("Bundesland");
      select.find("option.default").attr("selected", "true");
      select.addClass("austrich");
    }
  });
}


function bindFieldStatus() {
  "use strict";
  var value,
      parent,
      trigger_parent,
      name,
      target_name,
      $anfrageform,
      gparent;
  $('input[type=text]').each(function() {
    if ($(this).val()) {
      $(this).addClass("edited");
      if ($(this).attr('name') === "firstname") {
        $("#infoname span.firstname").html($(this).val());
      }
      if ($(this).attr('name') === "name") {
        $("#infoname span.name").html($(this).val());
      }
    }
  });
  $('input[type=text]').change(function(){
    value = $(this).val();
    if (/\S/.test(value)) {
      $(this).addClass("edited");
    } else {
      $(this).removeClass("edited");
    }
    if ($(this).attr('name') === "firstname") {
      $("#infoname span.firstname").html(value);
    }
    if ($(this).attr('name') === "name") {
      $("#infoname span.name").html(value);
    }
  });
  $('input:checked').each(function() {
    if ($(this).val()) {
      parent = $(this).parent();
      parent.addClass("edited");
    }
  });
  $('input[type=checkbox]').change(function() {
    var parent = $(this).parent();
    if (this.checked === true) {
      parent.addClass("edited");
    } else {
      parent.removeClass("edited");
    }
  });
  $('input[type=radio]:checked').each(function() {
    name = $(this).attr("name");
    // Unchecker
    $('span.uncheck.' + name).show();
    $('span.uncheck.' + name).click(function() {
      target_name = $(this).attr('id');
      $('input[name=' + target_name +']').prop('checked', false).trigger('change');
      $('input[name='+ target_name + ']').each(function() {
        parent = $(this).parent();
        parent.removeClass("edited");
      });
      $('span.uncheck.' + target_name).hide();
    });
  });
  $('input[type=radio]').click(function() {
    trigger_parent = $(this).parent();
    name = $(this).attr("name");
    $('input[name='+ name + ']').each(function() {
      parent = $(this).parent();
      parent.removeClass("edited");
    });
    trigger_parent.addClass("edited");
    // Unchecker
    $('span.uncheck.' + name).show();
    $('span.uncheck.' + name).unbind("click").click(function() {
      target_name = $(this).attr('id');
      $('input[name=' + target_name +']').prop('checked', false);
      $('input[name='+ target_name + ']').each(function() {
        parent = $(this).parent();
        parent.removeClass("edited");
      });
      $('span.uncheck.' + target_name).hide();
    });
  });
  $('select').each(function() {
    value = $(this).val();
    if (/\S/.test(value)) {
      gparent = $(this).parent().parent();
      gparent.addClass("edited");
      if ($(this).attr("name") === "forwardto") {
        $("span.forwardtocontact").addClass("active");
      }
      if ($(this).attr("name") === "forwardfrom") {
        $("span.forwardfromcontact").addClass("active");
      }
    }
  });
  $('select').change(function(){
    value = $(this).val();
    gparent = $(this).parent().parent();
    if (/\S/.test(value)) {
      gparent.addClass("edited");
      if ($(this).attr("name") === "forwardto") {
        $("span.forwardtocontact").addClass("active");
      }
      if ($(this).attr("name") === "forwardfrom") {
        $("span.forwardfromcontact").addClass("active");
      }
    } else {
      gparent.removeClass("edited");
      if ($(this).attr("name") === "forwardto") {
        $("span.forwardtocontact").removeClass("active");
      }
      if ($(this).attr("name") === "forwardfrom") {
        $("span.forwardfromcontact").removeClass("active");
      }
    }
  });

  if (get_template_name() ==='consulting_form'){
    $anfrageform = $('#anfrageform');
    var $alertError = $anfrageform.find('.alert[data-type="error"]');
    var $alertSaving = $anfrageform.find('.alert[data-type="saving"]');
    $anfrageform.find('input, select, textarea').change(function(){
      $alertSaving.removeClass('hidden');
      $alertError.addClass('hidden');
      $.post($anfrageform.attr('action'), $anfrageform.serialize())
       .fail(function(){
          $alertError.removeClass('hidden');
       })
       .always(function(){
         $alertSaving.addClass('hidden');
       });
    });
    $( window ).unload(function() {
      $.post($anfrageform.attr('action'), $anfrageform.serialize());
    });
  }

}


function setEmailRequired() {
  "use strict";
  if ($('select[name=sendby]').val() === "email") {
    $('input[name=email]').addClass("required");
  }
  $('select[name=sendby]').change(function(){
    var value = $(this).val();
    if (value === "email" ) {
      $('input[name=email]').addClass("required");
    } else {
      $('input[name=email]').removeClass("required");
    }
  });
}


function optionalSelectOptions() {
  "use strict";
  $('select.optional').change( function() {
    var select = $(this);
    var value = select.val();
    if (!value || value === '') {
      $(select).find('option').each(function () {
        $(select).prop('selected', false);
      });
      //
      var modal = $('#consultingModal');
      modal.find(".modal-dialog").attr("id", "add_option");
      modal.find(".modal-body").empty();
      modal.find(".modal-body").append('Bitte anderen Wert angeben:');
      modal.find(".modal-body").append('<input type="text" name="other" />');

      modal.find(".modal-footer").empty();
      modal.find(".modal-footer").append('<button type="button" class="btn btn-default" data-dismiss="modal">Abbrechen</button>');
      modal.find(".modal-footer").append('<button type="button" class="btn btn-primary">Wert übernehmen</button>');
      modal.modal('show');
      modal.find('button.btn-primary').click(function () {
        var other = modal.find('input[name=other]').val();
        if (!other) return false;
        var new_option = '<option value="' + other + '">';
        new_option += other + '</option>';
        $(select).find('option.addOption').before(new_option);
        $(select).val(other);
        $('#anfrageform').submit();
        //
        modal.modal('hide');
      });
    }
  });
}


function bindForwardSelectionAdditonal() {
  "use strict";
  $('select.forward').each( function() {
    var select = $(this);
    var value = select.val();
    if (value === 'infodienst') {
      $(this).parent().find("input[type=text]").show();
    }
  });
  $('select.forward').change( function() {
    var select = $(this);
    var value = select.val();
    if (value === 'infodienst') {
      $(this).parent().find("input[type=text]").show();
    }  else {
      $(this).parent().find("input[type=text]").hide();
    }
  });
}


var handleEmailAssigned = function() {
  "use strict";
  var pause_email_list_loading = false;

  if(pause_email_list_loading !== true) {
    load_email_list();
  }
  pause_email_list_loading = true;
  setInterval(function() { pause_email_list_loading = false; }, 1000);
  updateTicketEmailsCounter();
};


var updateTicketEmailsCounter = function() {
  "use strict";
  var url,
    params;
  params = location.search.replace('tid', 'uid');
  url = '/ticket_emails_counter' + params;

  $.getJSON( url)
    .done(function( data ) {
      if (!data) {
        data = {inbox: 0, outbox: 0};
      }
      $('.inbox-headline .counter').html(data.inbox);
      $('.outbox-headline .counter').html(data.outbox);
    })
  ;
};


$(document).ready(function(){
  "use strict";
  var form = $("#anfrageform");

  form.bind("keypress", function (e) {
    if (e.keyCode === 13) {
      if(!$(e.target).hasClass("textarea")) {
        return false;
      }
    }
  });

  if (form.size() > 0) {
    optionalSelectOptions();
    enableFormScroll();
    setQuestionsEdited();
    formSubmitPreStep();
    noticeToggle();
    bindInboxOutboxTabs();
    bindQuestionerAppendix();
    bindShipping();
    bindFieldStatus();
    setEmailRequired();
    bindState("");
    bindState("shipping");
    bindForwardSelectionAdditonal();
    $(window).scroll(function(){
      formOnScroll();
    });
  }

  if (get_template_name() ==='consulting_form'){
    load_email_list();
    bindReplyEmailAction();
    bindReplyEmailSendAction();
    socketio.removeAllListeners('email_assigned');
    socketio.on('email_assigned', handleEmailAssigned);
  }

  $('#consulting-form-notice').autosize();
});
