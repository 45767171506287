/*global saveInfopacketContent,updateinfopacketView*/
(function ($) {
  "use strict";

  // Click on "Drucken"-Button for infopacket letter View & freigabeliste => Print PDF or CLose Ticket
  $(document).on('click', 'form.send-letter-form input[value=Drucken]', function(e) {
    e.preventDefault();
    if ($(this).hasClass("active")) {

      var modal = $('#consultingModal');
      modal.find(".modal-dialog").attr("id", "infopacket_print_ticket_select");
      modal.find(".modal-body").empty();

      var tid = $('form.send-letter-form input[name=tid]').val();
      var sendby = $('form.send-letter-form input[name=sendby]').val();
      var has_infopacket = $('#infopacket-send-entries').hasClass('infopacket');
      var text = "";

      var letter_tag = '';
      if (sendby === 'letter') {
        letter_tag += '<div id="print_letter" class="option">';
        letter_tag += '<form ';
        if ($("input[name=printed]").length > 0) {
          letter_tag += 'class="active" ';
        }
        letter_tag += 'action="'+ portal_url +'/@@letter_as_pdf?tid=' + tid + '" method="post" target="_blank" name="print-infopacket">';
        letter_tag += '<input type="hidden" value="letter" name="sendby" />';
        if ($('form.send-letter-form input[name=text]').length > 0) {
          text = $('form.send-letter-form input[name=text]').val();
          letter_tag += '<input class="hiddentext" type="hidden" value="' + text + '" name="text" />';
        }
        letter_tag += '<p class="title letter">Anschreiben<span class="dkg-icon" /></p>';
        letter_tag += '<input class="button print_letter" type="submit" value="Drucken" name="print_letter" />';
        letter_tag += '</form>';
        letter_tag += '</div>';
      }

      if (sendby === 'email') {
        letter_tag += '<div id="print_email" class="option">';
        letter_tag += '<form ';
        if ($("input[name=printed]").length > 0) {
          letter_tag += 'class="active" ';
        }
        letter_tag += 'action="'+ portal_url +'/@@emails_as_pdf?tid=' + tid + '" method="post" target="_blank" name="print-emails">';
        letter_tag += '<input type="hidden" value="email" name="sendby" />';
        if ($('form.send-letter-form input[name=text]').length > 0) {
          text = $('form.send-letter-form input[name=text]').val();
          letter_tag += '<input class="hiddentext" type="hidden" value="' + text + '" name="text" />';
        }
        letter_tag += '<p class="title email">Emails<span class="dkg-icon" /></p>';
        letter_tag += '<input class="button print_emails" type="submit" value="Drucken" name="print_emails" />';
        letter_tag += '</form>';
        letter_tag += '</div>';
      }

      var infopacket_tag = '';
      infopacket_tag += '<div id="print_infopacket" class="option">';
      infopacket_tag += '<form ';
      if ($("input[name=printedinfopacket]").length > 0) {
        infopacket_tag += 'class="active" ';
      }
      infopacket_tag +='action="'+ portal_url +'/@@infopaket_as_pdf?tid=' + tid + '" method="post" target="_blank" name="print-infopacket">';
      infopacket_tag += '<input type="hidden" value="letter" name="sendby" />';
      if ($('form.send-letter-form input[name=text]').length > 0) {
        text = $('form.send-letter-form input[name=text]').val();
        infopacket_tag += '<input class="hiddentext" type="hidden" value="' + text + '" name="text" />';
      }
      infopacket_tag += '<p class="title infopacket">Infopaket<span class="dkg-icon" /></p>';
      infopacket_tag += '<input class="button" type="submit" value="Drucken" name="print_infopacket" />';
      infopacket_tag += '</form>';
      infopacket_tag += '</div>';

      var material_tag = '';
      material_tag += '<div id="print_material" class="option last">';
      material_tag += '<form ';
      if ($("input[name=printedlist]").length > 0) {
        material_tag += 'class="active" ';
      }
      material_tag += 'action="'+ portal_url +'/@@materiallist_as_pdf?tid=' + tid + '" method="post" target="_blank" name="print-infopacket">';
      material_tag += '<input type="hidden" value="letter" name="sendby" />';
      if ($('form.send-letter-form input[name=text]').length > 0) {
        text = $('form.send-letter-form input[name=text]').val();
        material_tag += '<input class="hiddentext" type="hidden" value="' + text + '" name="text" />';
      }
      material_tag += '<p class="title materiallist">Materialliste<span class="dkg-icon" /></p>';
      material_tag += '<input class="button" type="submit" value="Drucken" name="print_materiallist" />';
      material_tag += '</form>';
      material_tag += '</div>';

      modal.find(".modal-body").append(letter_tag);
      if (has_infopacket) {
        modal.find(".modal-body").append(infopacket_tag);
      }
      modal.find(".modal-body").append(material_tag);

  //    modal.find(".modal-body").append(close_tag);
      modal.find(".modal-footer").empty();
      modal.find(".modal-footer").append('<button type="button" class="btn btn-default" data-dismiss="modal">Abbrechen</button>');


      modal.find(".modal-body").css("width", "580px");
      modal.modal('show');

      $(document).on('click', 'input.button', function(e) {
        $(this).parent().addClass("active");
        if ($(this).hasClass("print_letter")) {
          localStorage.setItem("uids", "");
          localStorage.setItem("tid", "");
          localStorage.removeItem("uids");
          localStorage.removeItem("tid");
        }
      });
    }
  });

  // Click on "Versenden"-Button for infopacket email View & freigabeliste => Send Email or CLose Ticket
  $(document).on('click', 'form.send-email-form input[value=Versenden]', function(e) {
    e.preventDefault();
    if ($(this).hasClass("noemail")) {
      alert("Es ist keine E-Mail-Adresse im Dokumentationsformular eingetragen. Daher kann keine Mail versendet werden.");
    } else if ($(this).hasClass("active")) {

      var modal = $('#consultingModal');
      modal.find(".modal-dialog").attr("id", "infopacket_finish_ticket_select");
      modal.find(".modal-body").empty();

      var tid = $('form.send-email-form input[name=tid]').val();
      var print_tag = '';
      print_tag += '<div id="send_email" class="full">';
      print_tag += '<form action="'+ portal_url +'/@@send_ticket" method="post" name="send-infopacket">';
      print_tag += '<input type="hidden" value="' + tid + '" name="tid" />';

      if ($('form.send-email-form input[name=send_encrypted]').is(':checked')) {
        print_tag += '<input type="hidden" value="true" name="send_encrypted" />';
      }

      print_tag += '<input type="hidden" value="email" name="sendby" />';
      if ($('form.send-email-form input[name=cc]').length > 0) {
        var cc = $('form.send-email-form input[name=cc]').val();
        print_tag += '<input class="hiddentext" type="hidden" value="' + cc + '" name="cc" />';
      }

      if ($('form.send-email-form input[name=subject]').length > 0) {
        var subject = $('form.send-email-form input[name=subject]').val();
        print_tag += '<input class="hiddentext" type="hidden" value="' + subject + '" name="subject" />';
      }
      if ($('form.send-email-form input[name=text]').length > 0) {
        var text = $('form.send-email-form input[name=text]').val();
        print_tag += '<input class="hiddentext" type="hidden" value="' + text + '" name="text" />';
      }
      print_tag += '<input class="button" type="submit" value="Email versenden" name="send_email" />';
      print_tag += '</form>';
      print_tag += '</div>';

      var close_tag = '';
      close_tag += '<div id="close_ticket" class="right">';
      close_tag += '<form action="'+ portal_url +'/@@close_infopacket" method="post" name="close-infopacket">';
      close_tag += '<input type="hidden" value="' + tid + '" name="tid" />';
      close_tag += '<input class="button" type="submit" value="Vorgang schliessen" name="close_ticket" />';
      close_tag += '</form>';
      close_tag += '</div>';

      modal.find(".modal-body").append(print_tag);
      modal.find(".modal-footer").empty();
      modal.find(".modal-footer").append('<button type="button" class="btn btn-default" data-dismiss="modal">Abbrechen</button>');
      modal.modal('show');
    }
  });


}(jQuery));
