/*jshint strict: true, undef: true*/


/*jshint unused: false */
// helper functions:
// please add these functions to globals in .jshintrc


// helper function to allow var.startsWith("mystring")
String.prototype.startsWith = function(str){
  "use strict";
  return (this.match("^"+str)==str);
};

// helper to get the plone template name from body class

/* exported get_template_name */
function get_template_name(){
  "use strict";
  var template_name = '';
  if ($('body').attr('class') === undefined) {
    return;
  }
  $.each(
    $('body').attr('class').split(' '),
    function(index, klass){
      if(klass.startsWith('template-')){
        template_name = klass.split('-')[1];
      }
    }
  );
  return template_name;
}

// helper to parse url parameters:
// example:
// qparameters = new Query_parameters(location.search);
// if ('show_for' in qparameters){}
function Query_parameters (querystring) {
  "use strict";
  if (querystring === '') return;
  var wertestring = querystring.slice(1);
  var paare = wertestring.split("&");
  var paar, name, wert;
  for (var i = 0; i < paare.length; i++) {
    paar = paare[i].split("=");
    name = paar[0];
    wert = paar[1];
    name = unescape(name).replace("+", " ");
    wert = unescape(wert).replace("+", " ");
    this[name] = wert;
  }
}


// jquery plugin to replace text
$.fn.replaceText = function( search, replace, text_only ) {
  "use strict";
  return this.each(function(){
    var node = this.firstChild,
      val,
      new_val,
      remove = [];
    if ( node ) {
      do {
        if ( node.nodeType === 3 ) {
          val = node.nodeValue;
          new_val = val.replace( search, replace );
          if ( new_val !== val ) {
            if ( !text_only && /</.test( new_val ) ) {
              $(node).before( new_val );
              remove.push( node );
            } else {
              node.nodeValue = new_val;
            }
          }
        }
      } while ( node = node.nextSibling );  // jshint ignore:line
    }
    remove.length && $(remove).remove(); // jshint ignore:line
  });
};

// jquery plugin: bootstrap alert messages function
$.fn.bootstrapAlert = function(alertMsg, alertType, alertCloseable){
  "use strict";
  alertCloseable = alertCloseable || true;
  alertType = alertType || 'success';
  return this.each(function(index, val){
    var alertHtml = "";
    alertHtml += '<div class="alert alert-' + alertType;
    if(alertCloseable === true){
      alertHtml += ' alert-dismissable';
    }
    alertHtml += '">';
    if(alertCloseable === true){
      alertHtml += '<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>\n';
    }
    alertHtml += alertMsg;
    $(this).html(alertHtml);
  });
};
