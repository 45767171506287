var app = angular.module('presence', ['firebase', 'ngDragDrop']);


app.factory('consultantsListService', ['$http',
  function($http) {
    'use strict';

    var runUserRequest = function() {
      return $http({
        method: 'GET',
        url: portal_url + '//consultants_list'
      });
    };
    return {
      consultantsList: function() {
        return runUserRequest();
      },
    };
}]);


app.filter('isOnline', function() {
  "use strict";
  return function(users, scope) {
    var consultants = scope.consultants;
    // Filter out the current logged in consultant from the list
    var current_user = $('#submit-to-review').attr('data-current-user');
    $.each(consultants, function(key, value) {
      if (value === current_user) {
        consultants.splice($.inArray(value, consultants),1);
      }
    });

    // Convert the array to an object (XXX: This needs to be refactored)
    var filtered = {};
    $.each(consultants, function(key, value) {
      filtered[value] = "Offline";
    });

    /* XXX: Not working yet. We want to fetch the consultants from the db
    var promise = get_consultant_list();
    promise.success(function (data) {
      var online_status = {};
      var colleagues = $.parseJSON(data).colleagues;
      $.each(colleagues, function (index, value) {
        online_status[value.user] = 'Offline';
      });
    });
    */

    // Set online status for the users
    if (users !== undefined) {
      $.each(users, function (key, user) {
        if (user.online && user.online != {} && key in filtered) {
          filtered[key] = 'Online';
        }
      });
    }
    return filtered;
  };
});

app.controller('PresenceController', ['$scope', '$http', '$timeout', 'angularFire', 'consultantsListService',
  function($scope, $http, $timeout, angularFire, consultantsListService) {
    "use strict";
    var url = $scope.firebase_url;
    var authToken = $scope.auth_token;
    $scope.clearLocalStorage = true;
    $scope.username = $scope.plone_username;

    $scope.consultants = [];

    var timeout = $timeout(function() {
      consultantsListService.consultantsList()
      .success(function(data, status) {
        $scope.consultants = data;
      });
    }, 350);

    $scope.setReviewer = function(name) {
      $("input[name='toreview']").attr("value", name);
      $scope.selected = name;
      if (name == "withoutreview") {
        if ($("input[name='sendby']").attr("value") == "letter") {
          $("#submit-infopacket").attr("value", "Drucken");
        } else {
          $("#submit-infopacket").attr("value", "Versenden");
        }
        $("#submit-infopacket").removeClass("orange").addClass("green");
      } else {
        $("#submit-infopacket").attr("value", "Einreichen");
        $("#submit-infopacket").removeClass("green").addClass("orange");
      }
    };

    $scope.isSelected = function(name) {
      return $scope.selected == name;
    };

    $scope.disableClearLocalStorage = function () {
      $scope.clearLocalStorage = false;
    };

    $scope.submit = function () {
      if ($scope.clearLocalStorage) {
        localStorage.setItem("uids", "");
        localStorage.setItem("tid", "");
        localStorage.removeItem("uids");
        localStorage.removeItem("tid");
      }
    };

    // Log me in.
    var dataRef = new Firebase(url);
    dataRef.auth(authToken, function(error, result) {
      if (error) {
        throw new Error("Login Failed! \n" + error);
      }
    });

    // Parse the url to find its root
    // A neat trick: we use the DOM to parse our url.
    // After setting parser.href, you can use:
    //
    // parser.protocol
    // parser.hostname
    // parser.port
    // parser.pathname
    // parser.search
    // parser.hash
    // parser.host
    //
    var parser = document.createElement('a');
    parser.href = url;
    var rootUrl = parser.protocol + '//' + parser.hostname + '/';

    var onlineRef = new Firebase(url);
    var connectedRef = new Firebase(rootUrl + '.info/connected');

    connectedRef.on('value', function(snap) {
      if (snap.val() === true) {
        // We're connected (or reconnected)!  Set up our presence state and
        // tell the server to set a timestamp when we leave.
        var userRef = onlineRef.child($scope.plone_username);
        var connRef = userRef.child('online').push(1);
        connRef.onDisconnect().remove();
        userRef.child('lastSeen').onDisconnect().set(Firebase.ServerValue.TIMESTAMP);
      }
    });

    // bind the data so we can display it
    var promise = angularFire(onlineRef, $scope, 'users', {});

  }

]);


// FAVORITES APP in 'presence' App ???
app.controller('FavoritesdialogController', ['$scope', '$http',
  function($scope, $http) {
    "use strict";

    $scope.new_branch = "";
    // Get Favorites Dialog Data as json
    $scope.data = {};
    $http.get('get_favorites_dialog_data_json').success(function(data) {
      $scope.data = data;
    });
//    $scope.actual_branch_uid = $scope.data.uid;

    // Select a Branch -> Update Favorites Dialog Data
    $scope.selectBranch = function(branch_uid) {
      $http({
        url: 'get_favorites_dialog_data_json',
        method: 'GET',
        params: {'act_branch_uid': branch_uid},
      }).success(function(data) {
        $scope.data = data;
      });
    };

    // Edit / Delete Branch -> Update Favorites Dialog Data
    $scope.toggleEditMode = function() {
      if ( $('#favorites-folders-list').hasClass("edit") ) {
          $('#favorites-folders-list').removeClass("edit");
          $('#toggleeditmode').html("bearbeiten");
      } else {
          $('#favorites-folders-list').addClass("edit");
          $('#toggleeditmode').html("zurück");
      }
    };

    // Perform Action -> Update Favorites Dialog Data
    $scope.performAction = function(action, params) {
      params.action = action;
      params.act_branch_uid = $scope.data.act_branch_uid;
      // get branch Title from input Field
      if (action == "addBranch") {
        params.branch_title = $scope.new_branch;
      }
      if (action == "renameBranch") {
        params.branch_title = $("#rename-" + params.branch_uid).val();
      }
      // always pepare the branch title
      if (params.branch_title) {
        params.branch_title = params.branch_title.trim().replace(/ /g, '_');
      }
      // Call Action
      $http({
        url: 'get_favorites_dialog_data_json',
        method: 'GET',
        params: params,
      }).success(function(data) {
        $scope.data = data;

        // Toggle Back (ja oder nein)
        /*
        if ( $('#favorites-folders-list').hasClass("edit") ) {
          $scope.toggleEditMode()
        }
        */
      });
      $scope.new_branch = "";
    };


    $scope.droppedFavorite = function(event, ui, branch_uid, $branch_index) {
      // Get branch
      var dropped;
      if (branch_uid == $scope.data.uid) {
        dropped = $scope.data.dropped.pop();
      } else {
        dropped = $scope.data.branches[$branch_index].dropped.pop();
      }
      // Move Favorite
      $http({
        url: 'get_favorites_dialog_data_json',
        method: 'GET',
        params: {"action": "moveFavorite",
                 "act_branch_uid": $scope.data.act_branch_uid,
                 "to_branch_uid": branch_uid,
                 "favorite_uid": dropped.uid},
      }).success(function(data) {
        $scope.data = data;
      });
    };

  }
]);
