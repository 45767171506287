/*jshint strict: true, undef: true*/
/*global

  window,document,confirm,location,portal_url,socketio

*/



function mark_glossary_definitions(){
  "use strict";

  var glossary_url = portal_url + '/@@get_all_glossary_definitions_as_json',
    glodef_url = portal_url + '/@@get_glossary_definition_as_json',
    searchRegex,
    glodef,
    replaceDefinitionTimeoutId = 0,
    jqxhr;

  jqxhr = $.getJSON( glossary_url, function(data) {
    var items = [];
    function replaceDefinition(data) {
      glodef = data.pop();
      searchRegex = new RegExp(glodef.definition, 'g');
      $(".component-block .content *:not(h2):not(h3):not(a)").replaceText(
        searchRegex, '<a class="glossary-definition inactive" href="' + glodef_url + '?uid=' + glodef.uid + '">' + glodef.definition + '</a>');
      activate_glossary_tooltips();
      if (data.length !== 0) {
        setTimeout(function() {replaceDefinition(data);}, 0);
      }else{
        //activate_glossary_tooltips();
      }
    }
    replaceDefinition(data);

  });
}


function activate_glossary_tooltips(){
  "use strict";
  $(document).on('shown.bs.popover', function(e){
    // prevent hiding popover if one click inside the popover
    $('.popover').click(function(e){
      e.stopPropagation();
    });
  });

  $(".content .glossary-definition:not([data-original-title])")
    .popover({
      container: 'body',
      trigger: 'manual',
      html: 'true',
      placement: 'bottom'
    })
    .click(function() {
      $.ajax({
        url: $(this).attr('href'),
        context: this,
        dataType: 'json',
        success: function(glodef_content) {
          var $glossary_link = $(this);
          $("body").click(function(){
            $glossary_link.toggleClass('inactive active')
              .popover('hide');
          });
          $(this).attr('data-original-title', glodef_content.translation);
          $(this).attr('data-content', glodef_content.definition);
          $(this).toggleClass('inactive active').popover('show');
        }
      });
      return false;
    });
}

$(document).ready(function(){
  "use strict";
  if ($("body.portaltype-section").length !== 0) {
    mark_glossary_definitions();
  }
});
