/*jshint strict: true, undef: true*/
/*global $,jQuery,window,document,localStorage,confirm,location,portal_url*/
(function ($) {
  "use strict";

  var SearchableText = $('input[name="SearchableText"]');
  if (typeof SearchableText.val() != "undefined") {
    // disable browser autocomplete
    SearchableText.attr("autocomplete", "off");
    // autcomplete
    SearchableText.typeahead({
      updater: function (item) {
        // set searchfield value to item that has been selected by typeahead
        this.$element[0].value = item;
        setTimeout(function() {
          // submit the search after a short timeout period, without this the
          // searchfield will be empty during the form submission.
          $('#searchform').submit();
        }, 10);
        return item;
      },
      source: function (query, process) {
        var labels = [];
        var mapping = {};
        return $.post('@@solr-autocomplete', { term: query }, function (data) {
          $.each(JSON.parse(data), function (i, item) {
            mapping[item.label] = item.value;
            labels.push(item.label);
          });
          return process(labels);
        });
      }
    });
  }

}(jQuery));
