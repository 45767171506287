/*jshint strict: true, undef: true*/
/*global window,document,location,socketio,load_tickets,load_email_list,portal_url,
  fetch_emails,get_template_name,tmpl,initiateOverlayLinks
*/

function update_dashboard_info_counts(){
  "use strict";
  var count,
      url = '@@get_dashboard_info_counts';
  $.getJSON(url)
    .done(function(data) {
      $('.info.released .count').html(data.approved);
      $('.info.submitted .count').html(data.pending);
      $('.info.assigned .count').html(data.review);
    })
    //.fail(function() {
    //  $.bootstrapGrowl(
    //    "Es ist ein Fehler beim laden der Workflow-Zähler aufgetreten.\n" +
    //    "Bitte laden Sie die Seite erneut!",
    //    {type: 'danger', align: 'center'});
    //})

  ;
}


function update_dashboard_tickets(){
  "use strict";
  var url = $("#tickets").attr("rel"),
      form_params;
  form_params = $('#show_for_form').serialize();
  url = url + '/?' + form_params;
  load_tickets(url);
}


function bindAssiocatedTicketEmailAction (){
  "use strict";

  var selectorEmailActionTrigger = '#email-action-associated-ticket';
  $(selectorEmailActionTrigger).unbind();
  $(document).on('click', selectorEmailActionTrigger, function(e) {
    e.preventDefault();
    var modal = $('#consultingModal'),
      modalBodyText;

    modal.find(".modal-dialog").attr("id", "email-assiocated-ticket");
    modal.find(".modal-dialog").addClass("modal-lg");
    modal.find(".modal-title").text(
      "E-Mail in zugehörigen Vorgang verschieben");

    modal.find(".modal-footer").empty();
    modal.find(".modal-footer").append('<button type="button" class="btn btn-default btn-primary moveEmail">nur verschieben</button>');
    modal.find(".modal-footer").append('<button type="button" class="btn btn-default btn-primary moveEmailAndOpenTicket">verschieben und Vorgang öffnen</button>');
    modal.find(".modal-footer").append('<button type="button" class="btn btn-default" data-dismiss="modal">Abbrechen</button>');

    modal.find(".modal-body").empty();
    modalBodyText = "<h1>Was möchten Sie tun?</h1>";
    modalBodyText += "<ul>";
    modalBodyText += "<li>Nur die E-Mail in den Vorgang verschieben?</li>";
    modalBodyText += "<li>Die E-Mail in den Vorgang verschieben und den Vorgang öffnen?</li>";
    modalBodyText += "</ul>";
    modal.find(".modal-body").html( modalBodyText );
    modal.modal('show');
  });

  $(document).on(
      'click', '#consultingModal .btn.btn-primary.moveEmailAndOpenTicket',
      function(e) {
    e.preventDefault();
    emailMoveToTicket(true);
  });

  $(document).on(
      'click', '#consultingModal .btn.btn-primary.moveEmail',
      function(e) {
    e.preventDefault();
    emailMoveToTicket();
  });

  function emailMoveToTicket( redirect ) {
    redirect = redirect || false;

    var email_move_to_url = $(selectorEmailActionTrigger).attr('rel');

    $.get(email_move_to_url, function( ticket_url ) {
      if ( redirect === true ) {
        window.location.href = ticket_url;
      }else{
        $("#consultingModal").modal('hide');
      }
    });
  }
}


function load_tickets(url){
  "use strict";
  url = url || $("#tickets").attr("rel");
  var tickets_html;
  if (!url) {
    return false;
  }
  $.getJSON( url)
    .done(function( data ) {
      tickets_html = tmpl("ticket-list-table-tmpl", data);
      $('#tickets tbody').html(tickets_html);
      initiateOverlayLinks();
      bindTicketDroppable();
    })
    //.fail(function() {
    //  $.bootstrapGrowl(
    //    "Es ist ein Fehler beim laden der Ticketliste aufgetreten.\n" +
    //    "Bitte laden Sie deie Seite erneut!",
    //    {type: 'danger', align: 'center'});
    //})
  ;
}


function bindTicketDroppable () {
  "use strict";
  $("#tickets tr").droppable({
    hoverClass: "drop-hover",
    drop: function( event, ui ) {
      var url,
        email_url,
        element;
      element = $(ui.draggable);
      email_url = element.attr('rel');
      url = email_url + '/@@move_email_to?ticket_uid=' +
        $(this).attr('id').split('-')[1];
      $.ajax(url).done(function() {
        //load_email_list();
        //update_dashboard_tickets();
      });
      return false;
    }
  });
}


function bindTicketSelectable () {
  "use strict";
  var tid, tids, menu;
  var edit_url, print_url, transfer_url, close_url, unprint_url;
  var multiple = false;
  $(document).on('click', "#tickets tr", function(e) {
    if (e.ctrlKey) {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
      } else {
        $(this).addClass("active");
      }
    } else {
      if (!$(this).hasClass("active")) {
        $("#tickets tr").removeClass("active");
        $(this).addClass("active");
      } else {
        $("#tickets tr").removeClass("active");
      }
    }
  });

  // ??? https://github.com/sydcanem/bootstrap-contextmenu/blob/master/bootstrap-contextmenu.js
  $("#dashboard-tickets #tickets").on("contextmenu", "tr", function(e) {
    e.preventDefault();
    var tid,
      multiple,
      edit_url,
      print_url,
      print_pdf_url,
      transfer_url,
      close_url,
      unprint_url,
      menu;

    $("div.dropdown").remove();

    multiple = false;
    if ($("#tickets tr.active").length > 1) {

      multiple = true;
    }

    if (!multiple) {

      tid = $(this).attr("id").replace("ticket-", "");

      edit_url = portal_url + '/consulting_form?tid=' + tid;
      print_url = portal_url + '/ticket_as_html?tid=' + tid;
      print_pdf_url = portal_url + '/ticket_as_pdf?tid=' + tid;
      transfer_url = portal_url + '/transfer_ticket?tid=' + tid;
      close_url =  portal_url + '/close_ticket?tid=' + tid;
      unprint_url = portal_url + '/unprint_ticket?tid=' + tid;

      menu = '<div id="ticket-actions-menu" class="dropdown clearfix" style="position:absolute;z-index:3000;">';
      menu += '<ul class="dropdown-menu" role="menu" style="display:block;">';
      menu += '<li><a class="open-ticket" href="' + edit_url + '">öffnen</a></li>';
      menu += '<li><a class="print-ticket" href="' + print_url + '" target="_blank">drucken</li>';
      menu += '<li><a class="print-pdf-ticket" href="' + print_pdf_url + '" target="_blank">als PDF drucken</li>';
      menu += '<li><a class="overlay link-overlay transferticket" href="' + transfer_url + '">zuweisen</a></li>';
      menu += '<li><a class="overlay link-overlay transferticket" href="' + close_url + '">schließen</a></li>';

      if ($("#ticket-" + tid).hasClass("printed")) {
        menu += '<li><a class="unprint-ticket" href="' +  unprint_url + '">korrigieren</a></li>';
      }

      menu += '</ul>';
      menu += '</div>';

      $(menu).appendTo("body")
                .css("left", e.pageX)
                .css("top", e.pageY)
                .show();

      $("body").one('click', function () {
        e.preventDefault();
        e.stopPropagation();
        $("div.dropdown").remove();
      });
    }
    if (multiple) {

      tids = "";
      $("#tickets tr.active").each(function () {
        if (tids.length > 0) {
          tids += "|";
        }
        tids += $(this).attr("id").replace("ticket-", "");
      });

      close_url =  portal_url + '/close_ticket?tids=' + tids;

      menu = '<div id="ticket-actions-menu" class="dropdown clearfix" style="position:absolute;z-index:3000;">';
      menu += '<ul class="dropdown-menu" role="menu" style="display:block;">';
      menu += '<li><a class="overlay link-overlay transferticket" href="' + close_url + '">schließen</li>';
      menu += '</ul>';
      menu += '</div>';

      $(menu).appendTo("body")
                .css("left", e.pageX)
                .css("top", e.pageY)
                .show();

      $("body").one('click', function () {
        e.preventDefault();
        e.stopPropagation();
        $("div.dropdown").remove();
      });
    }
  });

}

function bindDBCLICKOnTickets() {
  "use strict";
  // open tickets on dblclick:
  $("#tickets tbody").on('dblclick', 'tr.ticket', function() {
    var url, tid;
    tid = $(this).attr("id").replace("ticket-", "");
    url =  portal_url + '/consulting_form?tid=' + tid;
    window.location.href = url;
  });
}


function bindSortTicketList() {
  "use strict";
  $("#tickets thead").on('click', 'th.sortable', function (e) {
    e.stopPropagation();
    var url = $("#tickets").attr("rel"),
        form_params;
    form_params = $('#show_for_form').serialize();
    url = url + '/?' + form_params;
    url = url + '&sort_on=' + $(this).attr("rel");
    if (!$(this).hasClass('sort_on') || $(this).hasClass("descending")){
      $("#tickets th.sortable").removeClass('ascending descending sort_on');
      $(this).addClass("ascending").addClass('sort_on');
      url = url + '&sort_order=ascending';
    }
    else{
      $("#tickets th.sortable").removeClass('ascending descending sort_on');
      $(this).addClass("descending").addClass('sort_on');
      url = url + '&sort_order=descending';
    }
    load_tickets(url);
  });
}


function load_dashboard_colleagues() {
  "use strict";
  var colleagues_html,
      assign_href,
      url = '@@get_consultant_list';
  $.getJSON(url)
    .done(function(data) {
      var qparameters,
          show_for;
      colleagues_html = tmpl('colleagues-selection-tmpl', data);
      $('#dashboard-show-for').html(colleagues_html);
      $('#dashboard-show-for').change(function() {
        update_dashboard_tickets();
      });
      qparameters = new Query_parameters(location.search);
      if ('show_for' in qparameters){
        show_for = qparameters.show_for;
        $('#dashboard-show-for option[value=' + show_for + ']')
          .attr('selected', true);
      }else{
        $('#dashboard-show-for option[value=' + show_for + ']');
      }
    });
}


function fetch_emails() {
  "use strict";
  $.ajax( "@@fetch_emails" )
    .done(function(data) {
    });
}


function bindFetchEmailsOnClick() {
  "use strict";
  $("#dashboard-inbox #inbox-refresh").click(function(){
    $(this).addClass("icon-spin");
    $.ajax( "@@fetch_emails" )
      .done(function(data) {
        if(data !== undefined){
          $.bootstrapGrowl(data, {type: 'danger', align: 'center'});
        }
        load_email_list();
        $("#dashboard-inbox #inbox-refresh").removeClass("icon-spin");
      });
  });
}


$(document).ready(function(){
  "use strict";
  if (get_template_name() ==='consulting_dashboard'){
    load_dashboard_colleagues();
    bindSortTicketList();
    bindDBCLICKOnTickets();
    update_dashboard_tickets();
    update_dashboard_info_counts();
    load_email_list();
    bindFetchEmailsOnClick();

    socketio.on('ticket_modified', function(obj) {
      update_dashboard_info_counts();
      update_dashboard_tickets();
    });
    socketio.on('ticket_added', function(obj) {
      update_dashboard_info_counts();
      update_dashboard_tickets();
    });
    socketio.on('email_assigned', function(obj) {
      load_email_list();
      update_dashboard_tickets();
    });
    socketio.on('update_email_inbox', function(obj) {
      load_email_list();
    });

    bindTicketSelectable();
    bindAssiocatedTicketEmailAction();
  }
});
