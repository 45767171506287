/*jshint strict: true, undef: true*/
/*global

  window,document,localStorage,confirm,location,portal_url,tmpl,
  bindTicketDroppable,bindInboxDraggable

*/


$(document).ready(function() {
  "use strict";

  var signatureNode = $(
    "body.template-settings #form-customfield-signature").wysiwyg({
    css: '/++theme++dkg.theme/consulting/css/editor.css',
    initialContent: '',
    controls: {
      h1: { visible: false },
      h2: { visible: false },
      h3: { visible: false },
      code: { visible: false },
      removeFormat: { visible: false },
      createLink: { visible: false },
      unLink: { visible: false },
      insertHorizontalRule: { visible: false },
      insertImage: { visible: false },
      insertTable: { visible: false },
      subscript: { visible: false },
      superscript: { visible: false },
      redo: { visible: false },
      undo: { visible: false },
      justifyCenter: { visible: false },
      justifyFull: { visible: false },
      justifyLeft: { visible: false },
      justifyRight: { visible: false },
      indent: { visible: false },
      outdent: { visible: false },
      strikeThrough: { visible: false },
      bold: { tooltip: "fett" },
      italic: { tooltip: "kursiv" },
      underline: { tooltip: "unterstrichen" },
      insertUnorderedList: { tooltip: "unsortierte Liste einfügen" },
      insertOrderedList: { tooltip: "sortierte Liste einfügen" }
    }
  });


  $("#settings-display-form #edit").on('click', function (){
    $("#settings-display-form").hide();
    $("#settings-edit-form form > *:not(#formfield-form-widgets-signature)")
      .show();
    $("#settings-edit-form #abbrechen").show();
    $("#settings-edit-form div.alert").hide();
    return false;
  });

  if($('#settings-edit-form .alert-danger').length !== 0){
    $("#settings-display-form").hide();
    $("#settings-edit-form form > *:not(#formfield-form-widgets-signature)")
      .show();
    $("#settings-edit-form #abbrechen").show();
  }

  $('#save-signature').unbind().click(function (e){
    e.preventDefault();

    // close old modal if one exists
    $("#email-signature .alert").alert('close');

    var url = portal_url + '/@@save-consultant-signature',
        signatureContent,
        $saveButton = $(this);
    $saveButton.button('loading');
    signatureContent = signatureNode.wysiwyg('getContent');
    $.post(url, {'signature_content': signatureContent})
      .done(function() {
        $('.template-settings #email-signature .bootstrap-message-wrapper').bootstrapAlert(
          '<strong>Info:</strong> Die Signatur wurde gespeichert.', 'success', true);
      })
      .always(function(){
        $saveButton.button('reset');
      })
    ;
  });

});

