/*jshint strict: true, undef: true*/
/*global window,document,localStorage,confirm,location,portal_url,tmpl,
  bindTicketDroppable,bindInboxDraggable,socketio,History,
  bindReplyEmailAction, bindReplyEmailAction,bindReplyEmailSendAction,
  get_template_name
*/

function change_tab(trigger,url) {
  "use strict";
  var acturl,
      pushurl,
      updateurl,
      resultsbox = $("#content"),
      parent;

  acturl = $(location).attr('href');
  pushurl = url.replace("ajax=true","push=true");

  // Push Actual State if not Ajax
  if ($(location).attr('href').indexOf("ajax=true") === -1) {
    History.replaceState(null, null, acturl);
  }
  History.pushState(null, null, pushurl);
  // Load Content
  if (resultsbox.length>0) {
    url = url.replace(/ /g, '|');
    resultsbox.load(url);
  }
  // ACTIVE CLASS and ACTUAL_TAB
  if (trigger) {
    parent = trigger.parentNode;
    if (parent.getAttribute("class") != "icons") {
      $("#searchnav li.active").removeClass("active");
      parent.setAttribute("class", "active");
    }
  }
  $("html, body").animate({ scrollTop: 0 }, 600);
}


function bindDeleteEmailConfirmModal(){
  "use strict";

  $(document).on('click', '#email-action-delete', function(e) {
    e.preventDefault();
    var modal = $('#consultingModal');
    modal.find(".modal-dialog").attr("id", "delete_email");
    modal.find(".modal-body").empty();
    modal.find(".modal-footer").empty();
    modal.find(".modal-title").text("E-Mail löschen?");
    modal.find(".modal-footer").append('<button type="button" class="btn btn-primary">Ja, E-Mail löschen</button>');
    modal.find(".modal-footer").append('<button type="button" class="btn btn-default" data-dismiss="modal">Abbrechen</button>');
    modal.modal('show');
  });

  $(document).on('click', '#consultingModal #delete_email .btn.btn-primary',
      function(e) {
    e.preventDefault();
    var delete_email_url = $("#email-action-delete").attr("rel");
    $.getJSON(
      delete_email_url, function(data) {
        modal.modal('hide');
        // load_email_list();
      }
    );
    var modal = $('#consultingModal');
  });
}


function bindInboxDraggable () {
  "use strict";
  $("#email-list tr").draggable({
    helper: "clone",
  });
}


function load_email_list(){
  "use strict";
  var url = $("#email-list").attr("rel"),
      items = [],
      email_url,
      email_list_html,
      template_name,
      $activeEmailItem;

  $("#email-list tbody").show();
  if (url ===  undefined) {
    return false;
  }
  $.getJSON( url, function( data ) {
    template_name = get_template_name();
    if(template_name == 'consulting_dashboard') {
      $activeEmailItem = $("#email-list tbody tr.active");
      email_list_html = tmpl("email-list-table-tmpl", data);
      $("#email-list thead").show();
      $('#email-list tbody').html(email_list_html);
      bindInboxDraggable();
      if ($activeEmailItem.size() !== 0) {
        activate_email_in_list($('#' + $activeEmailItem.attr('id')));
      }else{
        activate_email_in_list($("#email-list tbody tr:first-child"));
      }
      $("#email-list tbody tr").click(function(){
        activate_email_in_list($(this));
      });
      if($('#email-list tbody tr').length === 0){
        $("#email-list tbody").html('<tr><td colspan="4">Es liegen zur Zeit keine E-Mails vor.</td></tr>');
        $("#email-details").html("");
      }
    }else if(template_name == 'consulting_form') {
      $activeEmailItem = $("#email-list li.active");
      if (data.length === 0) {
        $("#email-list").html('<li>Es liegen zur Zeit keine E-Mails vor.</li>');
        $("#email-details").html('');
      }
      email_list_html = tmpl("email-list-ul-tmpl", data);
      $('#email-list').html(email_list_html);
      if ($activeEmailItem.size() !== 0) {
        activate_email_in_list($('#' + $activeEmailItem.attr('id')));
      }else{
        activate_email_in_list($("#email-list li:first-child"));
      }

      $("#email-list li").click(function(){
        activate_email_in_list($(this));
      });
    }
    function activate_email_in_list($emailItem){
      if($emailItem.length === 0) {
        return false;
      }
      $emailItem.siblings().removeClass('active');
      $emailItem.addClass('active read');
      if ($emailItem.attr('id') !== $activeEmailItem.attr('id')) {
        email_url = $emailItem.attr("rel") + '/@@email_as_json';
        load_email(email_url);
      }
    }
  });
}


function load_email(url){
  "use strict";
  var items = [],
      email_html,
      email_content_html,
      email_content_frame;
  if (url === undefined) {
    return false;
  }

  if (get_template_name() ==='consulting_form'){
    url = url + '?set_read_flag=1';
  }

  $("#email-details-spinner").show();
  $.getJSON( url, function( data ) {
    data.template = get_template_name();
    email_html = tmpl("email-details-tmpl", data);
    email_content_html = tmpl("email-details-content-tmpl", data);
    $('#email-head').replaceWith(email_html);
    email_content_frame = $("#email-content-frame").contents().find('body');
    email_content_frame.html(email_content_html);
    email_content_frame.attr('class', 'template-consulting_dashboard');

    load_assignable_colleagues();
    $("#email-action-assign").click(function (){
      $('#assignable-colleagues-selection').modal('show');
      return false;
    });
    $("#email-details-spinner").hide();
    bindDeleteEmailConfirmModal();
    bindEmailToGlobalInboxConfirmModal();
  });
}


function load_assignable_colleagues() {
  "use strict";
  var colleagues_html,
      assign_href,
      url = '@@get_colleagues';
  $.getJSON(url)
    .done(function(data) {
      colleagues_html = tmpl('colleagues-modal-tmpl', data);
      $('#assignable-colleagues-selection').html(colleagues_html);
      $('#assignable-colleagues-selection').find("a").each(
        function (index){
          assign_href = $('#email-action-assign').attr('href');
          assign_href += '?assign_to=' + $(this).attr('rel');
          $(this).attr('href', assign_href);
        }
      );
    });
}


function bindEmailToGlobalInboxConfirmModal(){
  "use strict";
  var modal = $('#consultingModal');

  $(document).on('click', '#email-action-to-global-inbox', function(e) {
    e.preventDefault();
    modal.find(".modal-dialog").attr("id", "email_to_inbox");
    modal.find(".modal-body").empty();
    modal.find(".modal-footer").empty();
    modal.find(".modal-title").text("E-Mail in globale Inbox verschieben?");
    modal.find(".modal-footer").append('<button type="button" class="btn btn-primary">Ja, E-Mail verschieben</button>');
    modal.find(".modal-footer").append('<button type="button" class="btn btn-default" data-dismiss="modal">Abbrechen</button>');
    modal.modal('show');
  });

  $(document).on('click', '#consultingModal #email_to_inbox .btn.btn-primary',
      function(e) {
    e.preventDefault();
    var email_to_inbox_url = $("#email-action-to-global-inbox").attr("rel");
    $.getJSON(
      email_to_inbox_url, function(data) {
        modal.modal('hide');
        //load_email_list();
      }
    );
  });
}


// initiate Overlay Consulting Style Links
function initiateOverlayLinks () {
  "use strict";

  $(document).on('click', "a.overlay", function(e) {
    var url;
    $("#shadow").show();
    url = $(this).attr("href");
    if (url.search("large") > 0) {
      $("#info").addClass("large");
    } else {
      $("#info").removeClass("large");
    }
    $("#info").load(url);
    $("#info").show();
    /* close again */
    $("#shadow").click(function() {
      $("#shadow").hide();
      $("#info").html("");
      $("#info").hide();
    });
    return false;
  });
}

function getFacettedNavigation () {
  "use strict";
  var url;
  if($("#searchnav-categories").length > 0) {
    url = $("#searchnav-categories").attr("data-url");
    if (url.length > 10) {
      $.getJSON( url, function( data ) {
        for (var key in data) {
          $("#" + key).append("<span class='facettcount'>(" + data[key] +")</span>");
        }
      });
    }
  }
}


$(document).ready(function(){
  "use strict";

  initiateOverlayLinks();

  // Disabled Links
  $('a.disabled').click(function(e) {
    e.preventDefault();
  });

  /*    // Popup Links
  $('a.popup').prepOverlay({
      subtype: 'iframe'
  });*/

  // NOT IMPLEMENTED Links
  $("a.notimplemented").click(function(e) {
    e.preventDefault();
    /* jshint ignore:start */
    alert("noch nicht implementiert");
    /* jshint ignore:end */
  });

  // PLACEHOLDER
  //$('input, textarea').placeholder();

// Disable Tooltip for Dashboard Freigabe Info
/*
  $("li.info > div").click(function (e) {
    $("div.tooltip").removeClass("active");
    $(this).find("div.tooltip").addClass("active");
    e.stopPropagation();
    $("#main").one( "click", function(e) {
      if (!$(this).hasClass("tooltip")) {
        $("div.tooltip").removeClass("active");
      }
    });
  });
*/

  // Navigation
  $("#navopener").click(function() {
    if ($("#medinfos").hasClass("active")) {
      $("#medinfos").slideUp();
      $("#medinfos").removeClass("active");
      $("#navopener").removeClass("open");
      $("#medinfo").removeClass("open");
    } else {
      $("#medinfos").slideDown();
      $("#medinfos").addClass("active");
      $("#navopener").addClass("open");
      $("#medinfo").addClass("open");
    }
  });

  // History revert to Saved State
  window.addEventListener('popstate', function(event) {
    var acturl = $(location).attr('href');
    if (acturl.indexOf("/kontaktdaten") === -1) {
      return false;
    }
    if ($("body").hasClass("template-consulting_research")) {
      return false;
    }
    var updateurl = "";
    if (acturl.indexOf("?") != -1) {
      updateurl = acturl + "&ajax=true";
    } else {
      updateurl = acturl + "?ajax=true";
    }
    var resultsbox = $("#content");
    if (resultsbox.length>0) {
      resultsbox.load(updateurl);
    }
  });

  getFacettedNavigation();

});

    $(document).ready(function () {
      "use strict";
      $('.subcomplexes-multiselect').multiselect({
        buttonWidth: '100%',
        buttonClass: 'btn btn-link',
        checkboxName: 'questionsubcomplex',
        buttonContainer: '<div class="btn-group multiselect-consulting_form"></div>',
        buttonText: function (options, select) {
          var complex_label = select.attr("data-complex-label");
          if (options.length === 0) {
            return complex_label;
          } else if (options.length >= 1) {
            return complex_label + " (" + String(options.length) + ")";
          } else {
            var labels = [];
            options.each(function () {
              if ($(this).attr('label') !== undefined) {
                labels.push($(this).attr('label'));
              } else {
                labels.push($(this).html());
              }
            });
            return labels.join(', ') + '';
          }
        },
        buttonTitle: function(options, select) {
          var labels = [];
          if (options.length === 0) {
            labels.push("Keine Angabe");
          }
          options.each(function () {
            labels.push($(this).text());
          });
          return labels.join(' - ');
        }
      });
      // Consulting Form
      $.fn.linkNestedCheckboxes = function () {
        var childCheckboxes = $(this).find('input[type=checkbox] ~ div ul li a label input[type=checkbox]');

        childCheckboxes.change(function(){
          var parent = $(this).closest("div.btn-group").prevAll("input[type=checkbox]").first();
          var anyChildrenChecked = $(this).closest("div ul").find("li a label input[type=checkbox]").is(":checked");
          $(parent).prop("checked", anyChildrenChecked);
        });

        // Parents
        childCheckboxes.closest("div.btn-group").prevAll("input[type=checkbox]").first().change(function(){
          $(this).nextAll("div.btn-group").first().find("li a label input[type=checkbox]")
            .prop("checked", $(this).prop("checked"));
        });

        return $(this);
      };
      $("div.multicheckbox-selector").linkNestedCheckboxes();

      $("input[value='gesund']").click(function() {
        $("input[name='requesttime_sub']").attr('onClick', 'return false;');
        $("input[name='requesttime_sub']").removeAttr('checked');
      });
      $("input[value='krebsverdacht']").click(function() {
        $("input[name='requesttime_sub']").attr('onClick', 'return false;');
        $("input[name='requesttime_sub']").removeAttr('checked');
      });
      $("input[value='verstorben']").click(function() {
        $("input[name='requesttime_sub']").attr('onClick', 'return false;');
        $("input[name='requesttime_sub']").removeAttr('checked');
      });
      $("input[value='erkrankt']").click(function() {
        $("input[name='requesttime_sub']").removeAttr('onClick');
      });
    });
