
// UPDATE FAVORITES BOX
function updateFavoritesBox() {
  "use strict";
  var url = "favoritesbox";
  $("#favorites-box").load(url);
}


// TOGGLE FAVORITES
function toggleFavorites(uid, trigger) {
  "use strict";
  var url = "favoritesbox?fuid=" + uid;
  if ( $(trigger).hasClass("selected")) {
    url += "&action=unset";
    $("#favorites-box").load(url);
    $(trigger).removeClass("selected");
  } else {
    url += "&action=set";
    $("#favorites-box").load(url);
    $(trigger).addClass("selected");
  }
}

$(document).ready(function(){
  "use strict";
  updateFavoritesBox();
});
