/*jshint strict: true, undef: true*/

var socketio;

$(document).ready(function(){
  "use strict";
  var socketio_url = location.protocol + '//' + location.hostname;

  socketio = io.connect(socketio_url);

});
