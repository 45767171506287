/*jshint strict: true, undef: true*/
/*global window,document,location,portal_url,socketio,get_template_name
*/

function update_ticket_workflow_page(obj){
  "use strict";
  $.get(
    '/check_ticket_uid_affects_me?uid=' + obj.uid,
    function(data) {
      if (data === true) {
        document.location.reload(true);
      }
    }
  );
}

$(document).ready(function(){
  "use strict";
  if (get_template_name() ==='freigabeliste'){
    socketio.on('ticket_modified', function(obj) {
      update_ticket_workflow_page(obj);
    });
  }

});
