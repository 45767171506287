// SWITCH MEDINFO AUDIENCE
// XXX: @Jan: jshint beschwert sich hier dass die Methode nicht verwendet wird
// in src/dkg.consulting/src/dkg/consulting/browser/templates/medinfo_detail.pt
// wird das aber verwendet in onclick="". Bitte über jQuery bindings lösen,
// damit wir den Check einschalten können.
function switch_audience(trigger) {
  "use strict";
  var switcher = $(trigger);
  if (trigger.getAttribute("class") != "special") {
    $("#medinfo-components-wrapper").css("float", "right");
    switcher.addClass("special");
    switcher.html("Basisinfo");
  } else {
    $("#medinfo-components-wrapper").css("float", "left");
    switcher.removeClass("special");
    switcher.html("Facharzt");
  }
}


function update_audience(audience) {
  "use strict";
  if (audience === undefined) {
    audience = localStorage.getItem("audience");
  } else {
    localStorage.setItem("audience", audience);
  }

  $("#navigation-audience li.active").removeClass("active");
  if (audience === "LA") {
    $("#navigation-audience li.LA").addClass("active");
    $("div.component-wrapper.header.green").addClass("full").show();
    $("div.component-block.green").addClass("full").show();
    $("#medinfo-components-wrapper").css("float", "left");
    //
    $("div.component-wrapper.header.yellow").hide().removeClass("full");
    $("div.component-block.yellow").hide().removeClass("full");
    $("div.component-wrapper.header.red").hide().removeClass("full");
    $("div.component-block.red").hide().removeClass("full");
    //
    $("#audience-switcher").hide();
  } else if (audience === "HA") {
    $("#navigation-audience li.HA").addClass("active");
    $("div.component-wrapper.header.yellow").addClass("full").show();
    $("div.component-block.yellow").addClass("full").show();
    $("#medinfo-components-wrapper").css("float", "left");
    //
    $("div.component-wrapper.header.green").hide().removeClass("full");
    $("div.component-block.green").hide().removeClass("full");
    $("div.component-wrapper.header.red").hide().removeClass("full");
    $("div.component-block.red").hide().removeClass("full");
    //
    $("#audience-switcher").hide();
  } else if (audience === "FA") {
    $("#navigation-audience li.FA").addClass("active");
    $("div.component-wrapper.header.red").addClass("full").show();
    $("div.component-block.red").addClass("full").show();
    $("#medinfo-components-wrapper").css("float", "left");
    //
    $("div.component-wrapper.header.green").hide().removeClass("full");
    $("div.component-block.green").hide().removeClass("full");
    $("div.component-wrapper.header.yellow").hide().removeClass("full");
    $("div.component-block.yellow").hide().removeClass("full");
    //
    $("#audience-switcher").hide();
  } else {
    $("#navigation-audience li.all").addClass("active");
    $("div.component-wrapper.header.green").removeClass("full").show();
    $("div.component-block.green").removeClass("full").show();
    $("div.component-wrapper.header.yellow").removeClass("full").show();
    $("div.component-block.yellow").removeClass("full").show();
    $("div.component-wrapper.header.red").removeClass("full").show();
    $("div.component-block.red").removeClass("full").show();
    //
    $("#audience-switcher").show();
  }
}


// SWITCH MEDINFO AUDIENCE CLASS
function switch_audience_view(trigger) {
  "use strict";
  var parent = $(trigger).parent();

  if (parent.hasClass("active")) { return; }

  $("#navigation-audience li.active").removeClass("active");
  update_audience(parent.attr("class"));
  parent.addClass("active");
}

(function ($) {
  "use strict";

  update_audience();
  $("#navigation-audience span.button").click(function() {
    switch_audience_view(this);
  });
  $("#audience-switcher").unbind("click").click(function() {
    switch_audience(this);
  });
}(jQuery));

