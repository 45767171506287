/*jshint strict: true, undef: true*/
/*global

  window,document,confirm,location,portal_url,socketio

*/


function activateLiteratureTooltips(){
  "use strict";
  // create a wrapper element to style literature popovers differently
  if($("#literatur-popover-container").length === 0){
    $("body").append('<div id="literature-popover-container"></div>');
  }

  $("a.literature-citation")
    .popover({
      container: '#literature-popover-container',
      trigger: 'manual',
      html: 'true',
      placement: 'bottom'
    })
    .css('display', 'inline-block')
    .click(function() {
      var $link = $(this);
      $("body").click(function(){
        $link.toggleClass('inactive active').popover('hide');
      });
      $link.toggleClass('inactive active').popover('show');
      return false;
    })
  ;

  $(document).on('shown.bs.popover', function(e){
    // prevent hiding popover if one click inside the popover
    $('.popover').click(function(e){
      e.stopPropagation();
    });
  });
}


$(document).ready(function(){
  "use strict";
  //activate_tooltips();
  activateLiteratureTooltips();

  // Last resort: Use timeout to activate literature tooltips after all.
  setTimeout(function () {
    activateLiteratureTooltips();
  }, 1000);
});

